body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #010b17;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  margin: 0 0 0 -40px;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.circle-ripple {
  background-color: #0e0e0e;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: ripple 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0em rgba(255, 255, 255, 0.1),
      0 0 0 2em rgba(255, 255, 255, 0.1), 0 0 0 8em rgba(255, 255, 255, 0.1),
      0 0 0 16em rgba(255, 255, 255, 0.1), 0 0 0 32em rgba(255, 255, 255, 0.1),
      0 0 0 56em rgba(255, 255, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0.1),
      0 0 0 8em rgba(255, 255, 255, 0.1), 0 0 0 16em rgba(255, 255, 255, 0.1),
      0 0 0 32em rgba(255, 255, 255, 0.1), 0 0 0 56em rgba(255, 255, 255, 0.1),
      0 0 0 76em rgba(255, 255, 255, 0);
  }
}
